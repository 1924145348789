import { StarProgress } from '@equinor/eds-core-react';
import { lazy, Suspense } from 'react';

export function lazyLoadRoutes(componentName: string) {
    const LazyElement = lazy(() => import(`../../components/${componentName}.tsx`));
  
    return (
      <Suspense fallback={<StarProgress style={{ marginLeft: "50%", marginTop: "5%" }} />}>
        <LazyElement />
      </Suspense>
    );
  }