import './App.css';
import { InteractionType } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import Auth from './authentication/Auth';

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <AuthenticatedTemplate>
      <BrowserRouter>
        <Auth />
      </BrowserRouter>
    </AuthenticatedTemplate>
  );
}

export default App;
