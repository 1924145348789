import { useLocation } from "react-router-dom";
import { Header } from "./header";
import { Router } from "./Routes";

const HeaderConatiner = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    return <Header isHomePage={isHomePage} />
}

export function Layout() {
    return (
        <div>
            <HeaderConatiner />
            <Router />
        </div>
    );
}