import { useMsal } from "@azure/msal-react";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
const isadmin = false;

export function Header(isHomePage: any) {
  const [loggedInUser, setLoggedInUser] = useState({});
  const { pathname } = useLocation();
  
  isHomePage = (pathname === '/' || pathname === '/home');
  const isAssetTagging = pathname === '/assettagging';
  const isAuditLog = pathname === '/auditlog';
  const isAsset = pathname === '/asset';
  const isCompany = pathname === '/company';
  const isRequest = pathname === '/request'
  const isEV = pathname === '/externalvendor'
  const isFAQ = pathname === '/faq';
  const isUserManualPage = pathname === '/usermanual';

  const [fontLoaded, setFontLoaded] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);
  const tokeno = localStorage.getItem('token');

  const { accounts } = useMsal();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
    }
  }, []);



  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-10px",
      }}>
      <Grid container sx={{ bgcolor: '#D5EAF4', padding: '20px 40px !important', width: '100%', height: "90px !important", fontFamily: "Equinor !important" }} alignItems="center !important">
        <Grid item xs={2.2}>
          <Typography component="div"
            sx={{
              display: "flex !important",
              alignItems: "center !important",
              color: "red !important",
              marginLeft: "25px !important",
              fontSize: "35px !important",
              fontFamily: "Equinor !important"
            }}>
            Work Hours
          </Typography>
        </Grid>
        <Grid item xs={6.5}>
          <Link
            to="/"
            color={isHomePage ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              
              color: 'black',
              textDecoration: 'none',
              borderBottom: isHomePage ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}>
            Home
          </Link>
          <Link
            to="/assettagging"
            color={isAssetTagging ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              marginLeft:'20px',
              fontFamily: "Equinor",
              color: 'black',
              
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isAssetTagging ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   Asset Tagging
          </Link>
          <Link
            to="/auditlog"
            color={isAuditLog ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              marginLeft:'20px',
              fontFamily: "Equinor",
              color: 'black',
              
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isAuditLog ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   Audit Log
          </Link>
          <Link
            to="/asset"
            color={isAsset ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              fontFamily: "Equinor",
              color: 'black',
              marginLeft:'20px',
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isAsset ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   Asset
          </Link>
          <Link
            to="/company"
            color={isCompany ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              fontFamily: "Equinor",
              color: 'black',
              marginLeft:'20px',
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isCompany ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   Company
          </Link>
          <Link
            to="/request"
            color={isRequest ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              fontFamily: "Equinor",
              color: 'black',
              marginLeft:'20px',
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isRequest ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   Request
          </Link>
          {/* <Link
            to="/externalvendor"
            color={isEV ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '20px',
              fontFamily: "Equinor",
              color: 'black',
              marginLeft:'20px',
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isEV ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '4px' // Push the underline below the text
            }}
          >   External Vendors
          </Link> */}
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Link
            to="/faq"
            color={isFAQ ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '17px',
              color: 'black',

              marginRight: "10px!important",
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isFAQ ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '2px' // Push the underline below the text
            }}
          >
            FAQ
          </Link>
          &nbsp;|&nbsp;
          <Link
            to="/usermanual"
            color={isUserManualPage ? '#FF0000' : 'inherit'}
            style={{
              fontSize: '17px',
              color: 'black',
              marginLeft: "10px !important",
              textDecoration: 'none',  // Remove the default underline
              borderBottom: isUserManualPage ? '3px solid #FF0000' : '3px solid transparent', // Adjust underline properties
              paddingBottom: '2px' // Push the underline below the text
            }}>
            User Manual
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}