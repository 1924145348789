import { InteractionRequiredAuthError, InteractionStatus, } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Layout } from "../components/layout/layout";
import { tokenRequest } from "./authConfig";
import ResponsibleUnit from "../components/Home/DawinciSynergiMapping/responsibleunit";
import { jwtDecode } from "jwt-decode";

function Auth() {
  const { instance, inProgress, accounts } = useMsal();
  interface MyToken {
    roles: string[];
  }

  useEffect(() => {
    const accessTokenRequest = {
      scopes: tokenRequest.scopes,
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          localStorage.setItem('token', accessTokenResponse.accessToken);
          let decoded = jwtDecode<MyToken>(accessTokenResponse.accessToken);
          localStorage.setItem('role',decoded?.roles.indexOf('Admin') !== -1 ? 'Admin' : '');
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        
        });
    }
  }, [instance, accounts, inProgress]);

  return (<Layout />);
}

export default Auth;