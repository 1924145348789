import React from 'react'
import ReactDOM from 'react-dom/client'
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { config } from './authentication/authConfig'
import App from './App'
import { ThemeProvider } from '@mui/material'
import theme from './Theme'

export const msalInstance = new PublicClientApplication(config)

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account);
    }
  })

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(

    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ThemeProvider>
    </React.StrictMode>,
  )
});