import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from './lazyloadingroutes';

export function Router() {
  const routes = [
    {
      path: '/',
      name: 'Home',
      element: lazyLoadRoutes('Home/home'),
    },
    {
      path: 'mapping',
      name: 'Mapping',
      element: lazyLoadRoutes('Home/mapping'),
    },
    {
      path: 'assettagging',
      name: 'assettagging',
      element: lazyLoadRoutes('AssetTagging/assettagging'),
    },
    {
      path: 'auditlog',
      name: 'auditlog',
      element: lazyLoadRoutes('AuditLog/auditlog'),
    },
    {
      path: 'asset',
      name: 'asset',
      element: lazyLoadRoutes('Assets/asset'),
    },
    {
      path: 'company',
      name: 'company',
      element: lazyLoadRoutes('Company/company'),
    },
    {
      path: 'request',
      name: 'request',
      element: lazyLoadRoutes('Request/request'),
    },
    {
      path: 'externalVendor',
      name: 'EV',
      element: lazyLoadRoutes('externalVendor/externalVendor'),
    },
    {
      path: 'faq',
      name: 'faq',
      element: lazyLoadRoutes('FAQ/faq'),
    },
    {
      path: 'usermanual',
      name: 'usermanual',
      element: lazyLoadRoutes('UserManual/usermanual'),
    }
  ];

  return useRoutes(routes);
}