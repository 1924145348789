import { LogLevel } from "@azure/msal-browser";

export const config = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStatedInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containspii: any) => {
                if (containspii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["User.Read","profile","openid"]
};
const apiScopes = ["openid", "profile", "offline_access"];
if(process.env.REACT_APP_TOKEN_SCOPE)
    apiScopes.push(process.env.REACT_APP_TOKEN_SCOPE);
export const tokenRequest = {
    scopes: apiScopes
};